import logo from './logo.svg';
import './App.css';
import React, { useRef } from 'react'
import { useGLTF, Stage, OrbitControls, Environment } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'

function App() {


  
  function Basin(props) {
    const { nodes, materials } = useGLTF('basin.glb')
    return (
      <group {...props} dispose={null}>
        <mesh geometry={nodes.b1.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.b2.geometry} material={materials['Material.001']} position={[1.364, -0.049, -29.364]} />
      </group>
    )
  }
  
  useGLTF.preload('basin.glb')
  



  return (
    <div>
      <Canvas style={{width:'100vw', height:'100vh'}}  frameloop="demand" shadows camera={{ position: [0, 100, 150], fov: 40, zoom: 0.8 }}>
      <OrbitControls/>
      <Stage preset='portrait' environment="forest" intensity={0.9}>
          
      <Basin />
      </Stage>
      </Canvas>
    </div>
  );
}

export default App;
